import React from 'react';
import Masonry from 'react-masonry-css';
import styled from 'styled-components';
import unified from 'unified';
import shuffle from 'lodash/shuffle';
import parse from 'remark-parse';
import remark2react from 'remark-react';
import { graphql, Link } from 'gatsby';
import { shortDate } from '../common/utils';
import Layout from '../components/Layout';

const Page = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px 10px 20px auto;
  .my-masonry-grid {
    display: flex;
    width: auto;
    margin-left: 30px;
    @media (max-width: 700px) {
      padding-left: 5px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }
`;

const About = styled.div`
  padding: 0 10px;
  flex: 1 0 420px;
  @media (max-width: 420px) {
    flex: 1 1 300px;
  }
  max-width: 520px;
`;

const PatchInner = styled.div`
  background-color: ${(props) => props.theme.color.patches[props.idx]};
  padding: 0 10px;
`;

const PatchWrap = styled.div`
  position: relative;
  width: 340px;
  @media (max-width: 700px) {
    width: 280px;
  }
  @media (max-width: 600px) {
    width: inherit;
    max-width: 500px;
  }
  cursor: pointer;
  &:hover {
    h2 {
      text-decoration: underline;
    }
  }
`;

const PatchZero = styled.div`
  background-color: ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.white};
  padding: 0 10px;
`;

const PatchVert = styled.div`
  margin: 0 10px;
  border-left: 1.3px dashed ${(props) => props.theme.color.white};
  border-right: 1.3px dashed ${(props) => props.theme.color.white};
  padding: 30px 10px;
  font-size: 16px;
`;

const PatchLine = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 20px;
  border-top: 1.3px dashed ${(props) => props.theme.color.white};
  border-bottom: 1.3px dashed ${(props) => props.theme.color.white};
`;

const Decal = styled.div`
  position: absolute;
  right: 5px;
  top: 8px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.primary};
  box-shadow: 1px -0.5px rgba(0, 0, 0, 0.6);

  & > div {
    margin: 3.7px;
    border: 1.3px dashed ${(props) => props.theme.color.white};
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
  }
`;

const Subheading = styled.div`
max-width: 260px;
@media (max-width: 700px) {
  max-width: 260px;
}
text-transform: uppercase;
color: ${(props) => props.theme.color.white};
font-family ${({ theme }) => theme.font.heading};
font-weight: 600;
font-size: 14px;

`;

const Patch = ({ node }, i) => {
  if (i === 0) {
    return (
      <PatchWrap key={i}>
        <Link to={node.fields.slug}>
          <PatchZero>
            <PatchLine />
            <PatchVert>
              <h2>{node.frontmatter.title}</h2>
              <div>
                {
                  unified()
                    .use(parse)
                    .use(remark2react)
                    .processSync(node.frontmatter.description).result
                }
              </div>
            </PatchVert>
          </PatchZero>
        </Link>
      </PatchWrap>
    );
  }
  return (
    <PatchWrap key={i}>
      <Link to={node.fields.slug}>
        <PatchInner idx={i < 7 ? i - 1 : Math.round(Math.random() * 7)}>
          <PatchLine />
          <PatchVert>
            <Subheading>
              {node.frontmatter.author}, {shortDate(node.frontmatter.date)}
            </Subheading>
            <Subheading>{node.frontmatter.location}</Subheading>
            <h2>{node.frontmatter.title}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: node.frontmatter.description }}
            />
          </PatchVert>
          <Decal>
            <div>#{node.frontmatter.patch}</div>
          </Decal>
        </PatchInner>
      </Link>
    </PatchWrap>
  );
};

const IndexTemplate = ({ data }) => {
  const { edges } = data.allMarkdownRemark;

  const sorted = data.intro.edges.concat(edges);

  return (
    <Layout>
      <Page>
        <About
          dangerouslySetInnerHTML={{ __html: data.about.edges[0].node.html }}
        ></About>
        <Masonry
          breakpointCols={{ default: 2, 600: 1 }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {sorted.map(Patch)}
        </Masonry>
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query IndexTemplate($patchesLimit: Int!, $patchesOffset: Int!) {
    allMarkdownRemark(
      limit: $patchesLimit
      skip: $patchesOffset
      filter: {
        frontmatter: {
          template: { eq: "patch" }
          draft: { ne: true }
          slug: { ne: "aerialpatchworkintro" }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            patch
            location
            author
          }
        }
      }
    }
    intro: allMarkdownRemark(
      filter: {
        frontmatter: {
          template: { eq: "patch" }
          draft: { ne: true }
          slug: { eq: "aerialpatchworkintro" }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            location
            author
          }
        }
      }
    }
    about: allMarkdownRemark(
      filter: {
        frontmatter: {
          template: { eq: "page" }
          draft: { ne: true }
          slug: { eq: "about" }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          html
        }
      }
    }
  }
`;

export default IndexTemplate;
